<!--  -->
<template>
  <el-date-picker
    class="date-picker-box"
    v-model="datePicker"
    :readonly="readonly"
    :disabled="disabled"
    :editable="editable"
    :type="type"
    :range-separator="rangeSeparator"
    :placeholder="placeholder"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :format="format"
    :value-format="valueFormat"
    :unlink-panels="unlinkPanels"
    :picker-options="pickerOptions"
    @change="handleChange"
    @blur="handleBlur"
    @focus="handleFocus" />
</template>

<script>
  // 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  // 例如：import 《组件名称》 from '《组件路径》';

  export default {
    name: "",
    mixins: [],
    // import引入的组件需要注入到对象中才能使用
    components: {},
    model: {
      prop: "value",
      event: "change",
    },
    props: {
      value: {
        type: [String, Array, Date],
        default() {
          return "";
        },
      },
      type: {
        type: [String],
        // year/month/date/week/ datetime/datetimerange/daterange
        // year/month/date/dates/ week/datetime/datetimerange/ daterange/monthrange
        default: "date",
      },
      readonly: {
        type: [Boolean],
        default: false,
      },
      disabled: {
        type: [Boolean],
        default: false,
      },
      editable: {
        type: [Boolean],
        default: true,
      },
      placeholder: {
        type: [String],
        default: "请输选择时间",
      },
      startPlaceholder: {
        type: [String],
        default: "请输选择开始时间",
      },
      endPlaceholder: {
        type: [String],
        default: "请输选择结束时间",
      },
      rangeSeparator: {
        type: [String],
        default: "至",
      },
      // 显示在输入框中的格式
      format: {
        type: [String],
        default: "", // 默认 type="date"=>yyyy-MM-dd || type="datetime"=>yyyy-MM-dd HH:mm:ss
      },
      // 指定绑定值的格式
      valueFormat: {
        type: [String],
        default: "",
      },
      unlinkPanels: {
        type: [Boolean],
        default: true,
      },
      pickerOptions: {
        type: [Object],
        default() {
          return {
            // 自定义快捷键
          };
        },
      },
    },
    data() {
      // 这里存放响应式数据
      return {};
    },
    // 监听属性 类似于data概念
    computed: {
      datePicker: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit("change", newValue);
        },
      },
    },
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {},
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会激活时调用
    deactivated() {}, // 如果页面有keep-alive缓存功能，这个函数会停用时调用
    // 方法集合
    methods: {
      handleChange() {},
      handleBlur(e) {
        this.$emit("blur", e);
      },
      handleFocus(e) {
        this.$emit("focus", e);
      },
    },
  };
</script>

<style lang="less" scoped></style>
