<!--  -->
<template>
  <div class="form-warp">
    <div class="top-bg"></div>
    <van-form @submit="onSubmit" :show-error="false">
      <div class="tips">请填写以下信息进行预约，工作人员会及时与您联系</div>
      <div class="warp tianshu-warp">
        <p class="label required">姓名（称呼）</p>
        <van-field v-model="form.name" :rules="rules.name" name="name" label="" placeholder="请输入姓名">
          <template v-slot:left-icon>
            <img class="left-icon" src="@/assets/img/name.png" alt="" />
          </template>
        </van-field>
      </div>

      <div class="warp tianshu-warp">
        <p class="label required">公司名称</p>
        <van-field
          v-model="form.companyName"
          :rules="rules.companyName"
          name="companyName"
          label=""
          placeholder="请输入公司名称">
          <template v-slot:left-icon>
            <img class="left-icon" src="@/assets/img/companyName.png" alt="" />
          </template>
        </van-field>
      </div>

      <div class="warp tianshu-warp">
        <p class="label">职位</p>
        <van-field v-model="form.position" name="position" label="" placeholder="请输入职位名称">
          <template v-slot:left-icon>
            <img class="left-icon" src="@/assets/img/position.png" alt="" />
          </template>
        </van-field>
      </div>

      <div class="warp tianshu-warp">
        <p class="label required">手机号码</p>
        <van-field
          v-model="form.phone"
          :rules="rules.phone"
          name="phone"
          label=""
          maxlength="11"
          placeholder="请输入手机号码">
          <template v-slot:left-icon>
            <img class="left-icon" src="@/assets/img/phone.png" alt="" />
          </template>
        </van-field>
      </div>

      <div class="warp tianshu-warp">
        <p class="label required">邮箱</p>
        <van-field v-model="form.email" :rules="rules.email" name="email" label="" placeholder="请输入邮箱">
          <template v-slot:left-icon>
            <img class="left-icon" src="@/assets/img/email.png" alt="" />
          </template>
        </van-field>
      </div>

      <div class="warp tianshu-warp">
        <p class="label required">预计开始测试日期</p>
        <template v-if="$uaParser.isMobile()">
          <van-field
            readonly
            v-model="form.fromOrderTime"
            :rules="rules.fromOrderTime"
            name="fromOrderTime"
            label=""
            placeholder="请选择预计开始测试日期"
            @click="showFromOrderTime = true">
            <template v-slot:left-icon>
              <img class="left-icon" src="@/assets/img/time.png" alt="" />
            </template>
          </van-field>
        </template>
        <template v-else>
          <van-field
            class="elment-van-field"
            v-model="form.fromOrderTime"
            :rules="rules.fromOrderTime"
            name="fromOrderTime"
            label=""
            placeholder="">
            <template #input>
              <DatePicker
                v-model="form.fromOrderTime"
                type="date"
                valueFormat="yyyy-MM-dd"
                :pickerOptions="pickerOptions"
                placeholder="请选择预计开始测试日期"
                @change="fromOrderTimeDatePicker" />
            </template>
          </van-field>
        </template>
      </div>
      <van-calendar v-model="showFromOrderTime" :max-date="maxDate" @confirm="onConfirmFromOrderTime" color="#00bedc" />

      <div class="warp tianshu-warp">
        <p class="label required">预计结束测试日期</p>
        <template v-if="$uaParser.isMobile()">
          <van-field
            readonly
            v-model="form.toOrderTime"
            :rules="rules.toOrderTime"
            name="toOrderTime"
            label=""
            placeholder="请选择预计结束测试日期"
            @click="showToOrderTime = true">
            <template v-slot:left-icon>
              <img class="left-icon" src="@/assets/img/time.png" alt="" />
            </template>
          </van-field>
        </template>
        <template v-else>
          <van-field
            class="elment-van-field"
            v-model="form.toOrderTime"
            :rules="rules.toOrderTime"
            name="toOrderTime"
            label=""
            placeholder="">
            <template #input>
              <DatePicker
                v-model="form.toOrderTime"
                type="date"
                valueFormat="yyyy-MM-dd"
                :pickerOptions="pickerOptions"
                placeholder="请选择预计结束测试日期"
                @change="toOrderTimeDatePicker" />
            </template>
          </van-field>
        </template>
      </div>
      <van-calendar v-model="showToOrderTime" :max-date="maxDate" @confirm="onConfirmToOrderTime" color="#00bedc" />

      <div class="warp tianshu-warp size-radio-group">
        <p class="label">需要服务器及GPU数量</p>
        <van-field v-model="form.size" name="size" label="" placeholder="请输入例如，单卡、单机4卡、单机8卡、2机16卡">
          <template v-slot:left-icon>
            <img class="left-icon" src="@/assets/img/server.png" alt="" />
          </template>
        </van-field>
        <!-- <van-field v-model="form.size" :rules="rules.size" name="size" label="" placeholder="">
          <template #input>
            <van-radio-group v-model="form.size" checked-color="#00bedc">
              <van-radio name="1">1</van-radio>
              <van-radio name="2">2</van-radio>
              <van-radio name="3">3</van-radio>
              <van-radio name="4">4</van-radio>
            </van-radio-group>
          </template>
        </van-field> -->
      </div>

      <div class="warp tianshu-warp">
        <p class="label">天数联络人</p>
        <van-field v-model="form.contacts" name="contacts" label="" placeholder="请输入联络人">
          <template v-slot:left-icon>
            <img class="left-icon" src="@/assets/img/contacts.png" alt="" />
          </template>
        </van-field>
      </div>

      <div class="warp tianshu-warp">
        <p class="label">备注</p>
        <van-field
          v-model="form.remarks"
          name="remarks"
          rows="2"
          :autosize="{ maxHeight: 150, minHeight: 50 }"
          type="textarea"
          label=""
          placeholder="请输入备注" />
      </div>

      <div class="submit">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  // 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  // 例如：import 《组件名称》 from '《组件路径》';
  import DatePicker from "@/components/DatePicker/index.vue";

  const validator = {
    name(value) {
      console.log("validator=>name", value);
      const reg = new RegExp(/^[A-Za-z\u4e00-\u9fa5]+$/, "g");
      if (!reg.test(value)) {
        return false;
      }
      return true;
    },
    phone(value) {
      const reg = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/, "g"); // ^[0-9]+$
      if (!reg.test(value)) {
        return false;
      }
      return true;
    },
    email(value) {
      const reg = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "g"
      );
      if (!reg.test(value)) {
        return false;
      }
      return true;
    },
  };

  const rules = {
    name: [
      {
        required: true,
        message: "请输入姓名",
        trigger: "onBlur",
      },
      { validator: validator.name, message: "请填写中文或英文名", trigger: "onBlur" },
    ],
    companyName: [
      {
        required: true,
        message: "请输入公司名称",
        trigger: "onBlur",
      },
    ],
    phone: [
      {
        required: true,
        message: "请输入手机号码",
        trigger: "onBlur",
      },
      { validator: validator.phone, message: "手机号格式有误", trigger: "onBlur" },
    ],
    email: [
      {
        required: true,
        message: "请输入邮箱",
        trigger: "onBlur",
      },
      { validator: validator.email, message: "邮箱格式有误", trigger: "onBlur" },
    ],
    fromOrderTime: [
      {
        required: true,
        message: "请选择预计开始测试日期",
        trigger: "onSubmit",
      },
    ],
    toOrderTime: [
      {
        required: true,
        message: "请选择预计结束测试日期",
        trigger: "onSubmit",
      },
    ],
    // size: [
    //   {
    //     required: true,
    //     message: "请选择需要服务器数量",
    //     trigger: "onSubmit",
    //   },
    // ],
  };

  export default {
    name: "",
    mixins: [],
    // import引入的组件需要注入到对象中才能使用
    components: {
      DatePicker,
    },
    props: {},
    data() {
      // 这里存放响应式数据
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 60 * 60 * 24 * 1000;
          },
        },
        maxDate: this.$dayjs().add(1, "year").$d,
        showFromOrderTime: false,
        showToOrderTime: false,
        rules,
        form: {
          username: "",
          companyName: "",
          email: "",
          fromOrderTime: "",
          toOrderTime: "",
          size: "",
        },
      };
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    async created() {},
    // 生命周期 - 挂载完成（可以访问DOM元素）
    async mounted() {
      // console.log(this.$dayjs().add(1, "year"));
    },
    async beforeCreate() {}, // 生命周期 - 创建之前
    async beforeMount() {}, // 生命周期 - 挂载之前
    async beforeUpdate() {}, // 生命周期 - 更新之前
    async updated() {}, // 生命周期 - 更新之后
    async beforeDestroy() {}, // 生命周期 - 销毁之前
    async destroyed() {}, // 生命周期 - 销毁完成
    async activated() {}, // 如果页面有keep-alive缓存功能，这个函数会激活时调用
    async deactivated() {}, // 如果页面有keep-alive缓存功能，这个函数会停用时调用
    // 方法集合
    methods: {
      // 预计开始测试日期 pc
      fromOrderTimeDatePicker(e) {
        console.log("fromOrderTimeDatePicker=>", e);
        this.onConfirmFromOrderTime(e);
      },
      // 预计结束测试日期 pc
      toOrderTimeDatePicker(e) {
        console.log("toOrderTimeDatePicker=>", e);
        this.onConfirmToOrderTime(e);
      },
      formatDate(date) {
        return this.$dayjs(date).format("YYYY-MM-DD");
      },
      // 预计开始测试日期
      onConfirmFromOrderTime(date) {
        console.log("onConfirmFromOrderTime", date);
        const formatDate = this.formatDate(date);
        this.form.fromOrderTime = formatDate;
        this.showFromOrderTime = false;
      },
      // 预计结束测试日期
      onConfirmToOrderTime(date) {
        console.log("onConfirmFromOrderTime", date);
        const formatDate = this.formatDate(date);

        const date1 = this.$dayjs(this.form.fromOrderTime);
        const date2 = this.$dayjs(formatDate);
        console.log("date1.diff(date2)", date1.diff(date2));
        if (date1.diff(date2) > 0) {
          this.$toast("预计结束测试日期小于预计开始测试日期");
          this.form.toOrderTime = "";
          return false;
        }

        this.form.toOrderTime = formatDate;
        this.showToOrderTime = false;
      },
      async onSubmit(values) {
        console.log("submit", values);
        console.log("this.form", this.form);
        const { code } = await this.$api.formReview(this.form);
        if (code === 0) {
          this.$toast({
            type: "success",
            message: "提交成功",
          });
          this.form = {};
        } else {
          this.$toast({
            type: "fail",
            message: "提交失败",
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-warp {
    /* 客户需求 */
    margin: 0 auto;
    max-width: 750px;

    /* background-color: rgba(226, 226, 226, 0.9); */

    /* @extend .safe-area-inset-bottom; */

    /* padding-bottom: 30px; */

    .top-bg {
      height: 254px;
      @include background("~assets/img/form-warp-bg.png", contain);
    }

    .van-form {
      margin: -115px 11px 0;
      padding: 13px 10px 20px;
      border-radius: 11px;
      background-color: #fff;

      .tips {
        margin-bottom: 10px;
      }

      .van-field {
        margin-bottom: 24px;
        padding: 4px 8px;
        border-radius: 6px;

        .left-icon {
          width: 12px;
          height: 14px;
        }

        :deep() {
          .van-field__value {
            .van-field__error-message {
              /* position: absolute;
              bottom: 0; */
            }
          }
        }
      }

      .elment-van-field {
        padding: 0;

        .date-picker-box {
          width: 100%;

          :deep {
            input {
              border: 0;
              font-size: 11px;
            }
          }
        }
      }
    }

    .tianshu-warp {
      .van-field {
        border: 1px solid #707070;
      }
    }

    .warp {
      .label {
        margin-bottom: 6px;
        color: #333;
        font-size: 12px;
        font-weight: bold;
      }

      .label.required {
        position: relative;

        &::before {
          content: "*";
          position: absolute;
          left: -8px;
          display: inline-block;
          color: #ff0505;
          font-size: 12px;
        }
      }
    }

    .size-radio-group {
      .van-radio-group {
        width: 100%;

        :deep() {
          .van-radio {
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  ::v-deep .van-field__control {
    font-size: 11px;
  }
</style>
